import {Injectable} from '@angular/core';
import {Serializer} from '@matchsource/api-utils';
import {Country} from '@matchsource/api-generated/common';
import {CountryModel} from '@matchsource/models/country';
import {CORD_RESTRICTED_VALUES, DONOR_RESTRICTED_VALUES, PATIENT_RESTRICTED_VALUES} from '@matchsource/models/shared';

@Injectable({
  providedIn: 'root',
})
export class CountrySerializerService implements Serializer<CountryModel, Country> {
  fromDTO(input: Country): CountryModel {
    return {
      code: input.code,
      name: input.name,
      isPatientRestricted: PATIENT_RESTRICTED_VALUES.includes(input.restrictionType),
      isDonorRestricted: DONOR_RESTRICTED_VALUES.includes(input.restrictionType),
      isCordRestricted: CORD_RESTRICTED_VALUES.includes(input.restrictionType),
    };
  }
}
