import {Injectable} from '@angular/core';
import {Serializer} from '@matchsource/api-utils';
import {State} from '@matchsource/api-generated/common';
import {CountryStateModel} from '@matchsource/models/country';

@Injectable({
  providedIn: 'root',
})
export class CountryStateSerializerService implements Serializer<CountryStateModel, State> {
  fromDTO(input: State): CountryStateModel {
    return {
      code: input.code,
      name: input.name,
      fullName: `${input.code} - ${input.name}`,
    };
  }
}
